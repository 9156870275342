<template>
  <div class="sort-wrap">
    <Button v-if="statusChecked" :class="`sort-btn ${(this.sort == 'new' || this.sort == 'old') ? 'active' : ''}`"
            @click="onChangeSort('new')">
      <span>Шинэ</span>
    </Button>

    <Button v-else :class="`sort-btn ${(this.sort == 'old' || this.sort == 'new') ? 'active' : ''}`"
            @click="onChangeSort('old')">
      <span>Хуучин</span>
    </Button>

    <Button v-if="orderChecked" :class="`sort-btn ${(this.sort == 'a-z' || this.sort == 'z-a') ? 'active' : ''}`"
            @click="onChangeOrder('z-a')">
      <span>А-Я</span>
    </Button>
    <Button v-else :class="`sort-btn ${(this.sort == 'a-z' || this.sort == 'z-a') ? 'active' : ''}`"
            @click="onChangeOrder('a-z')">
      <span>Я-А</span>
    </Button>
  </div>
</template>

<script>
export default {
  name: "SortDropdown",
  components: {},
  data() {
    return {
      statusChecked: false,
      orderChecked: false,
      statusSort: 'new',
      orderSort: 'a-z'
    }
  },

  created() {
    if (this.$route.params.sort != 'all') {
      this.sort = this.$route.params.sort;
    }
  },

  methods: {
    onChangeSort(val) {
      this.sort = val;

      let sort = val;
      if (sort == null) {
        sort = 'all'
      }
      this.statusChecked = !this.statusChecked;
      let cat = this.$route.params.cat ? this.$route.params.cat : 'all';
      let season = this.$route.params.season ? this.$route.params.season : 'all';
      let status = this.$route.params.status ? this.$route.params.status : 'all';
      this.$router.push(`/series/${cat}/${season}/${status}/${sort}`)
    },
    onChangeOrder(val) {
      this.sort = val;

      let sort = val;
      if (sort == null) {
        sort = 'all'
      }
      this.orderChecked = !this.orderChecked;
      let cat = this.$route.params.cat ? this.$route.params.cat : 'all';
      let season = this.$route.params.season ? this.$route.params.season : 'all';
      let status = this.$route.params.status ? this.$route.params.status : 'all';
      this.$router.push(`/series/${cat}/${season}/${status}/${sort}`)
    }
  }
}
</script>

<style scoped>

</style>
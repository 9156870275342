<template>
  <div class="cat-list cat-list-seeroo">
    <div v-if="isLoading"></div>
    <div v-else>
      <div>
        <router-link to="/series/all/all/all/all?t=Бүх ангилал"
                     :class="`btn btn-sm m-1 ${$route.params.cat == 'all' ? 'active' : ''}`">
          <div class="bg-layer" :style="{'background': '#003CAA'}"></div>
          <span>Бүх ангилал</span>
        </router-link>

        <router-link :to="`/series/${item.id}/all/all/all?t=${item.title}`" v-for="item in catList" :key="item.id"
                     :class="`btn btn-sm m-1 ${$route.params.cat == item.id ? 'active' : ''}`">
          <div class="bg-layer" :style="{background: item.color}"></div>
          <span>{{ item.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatList",
  props: ['singleCatMode'],
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    catList() {
      return this.$store.state['cats'];
    }
  },
  created() {
    this.$store.dispatch('getCats', {vm: this});
  }
}
</script>

<style scoped>

</style>